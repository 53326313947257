<template>
    <div class="popup_wrapper" @click="closePopup">
        <div class="v-popup">
            <div class="v-popup__header">
                <span>{{ name }}</span>
                <span>
                    <i class="fa fa-times" aria-hidden="true" @click="closePopup"></i>
                </span>
            </div>
            <div class="v-popup__content">
                <slot name="content"></slot>
            </div>
            <div class="v-popup__footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "popup-info",
    props: {
        name: {
            type: String
        }
    },
    methods: {
        closePopup() {
            this.$emit('closePopup')
        }
    }
}
</script>

<style lang="scss">
.popup_wrapper {
  background-color: rgba(64, 64, 64, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.v-popup {
  padding: 16px;
  width: 259px;
  position: fixed;
  top: 50%;
  background-color: #fff;
  z-index: 99;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #3D3D3D
  }

  &__content {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3D3D3D;
    padding-bottom: 8px;
  }

  &__footer {
    padding-top: 9px;
    border-top: solid 1px #c9c9c9;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #139460;
  }
}</style>