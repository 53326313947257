<template>
    <button class="collapse-btn" @click="isOpen = !isOpen">
        <span>{{ title }}</span>
        <i v-if="!isOpen" class="fa fa-angle-down" aria-hidden="true"></i>
        <i v-else class="fa fa-angle-up" aria-hidden="true" style="color: #2cc185;"></i>
    </button>
</template>

<script>

export default {
    name: "collapse-button",
    props: {
        title: {
            type: String,
        }
    },
    data() {
        return {
            isOpen: false
        }
    },
}
</script>

<style lang="scss" scoped>
.collapse-btn {
  width: 100%;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  text-align: start;
  height: 100px;
  border-top: solid 1px #c9c9c9;
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    height: 55px;
  }
}</style>