<template>
    <div class="analysis">
        <ul class="analysis__list">
            <li v-for="(value, sectionName, index) in sections" v-bind:key="index" class="analysis__content">
                <h3 class="analysis__title">{{ value[0] }}</h3>
                <p class="analysis__average">средний бал: {{ value[2] }}</p>
                <p class="analysis__description">Требуется для достижения KPI: {{ value[3] }} и более баллов</p>
                <BarChart :chartData="metadata(sectionName)"
                          :options="charOptions"
                          :width="chartWidth"
                          :height="chartHeight"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import {BarChart} from "vue-chart-3";
import {ref} from "@vue/reactivity";

export default {
    name: "additional-analysis",
    components: {BarChart},
    props: {},
    setup() {
        const store = useStore()
        const users = computed(() => store.state.analytics.referenceReport.users)
        const referenceReport = computed(() => store.state.analytics.referenceReport)
        const sections = {
            'total': ['Общий ЭИ', []],
            'domain_1': ['Опытный ЭИ', []],
            'domain_2': ['Стратегический ЭИ', []],
            'branch_1': ['Распознавание эмоций', []],
            'branch_2': ['Использование эмоций для решения задач', []],
            'branch_3': ['Понимание эмоций', []],
            'branch_4': ['Управление эмоциями', []],
            'section_1': ['Лица', []],
            'section_2': ['Фасилитация', []],
            'section_3': ['Изменения', []],
            'section_4': ['Управление своими эмоциями', []],
            'section_5': ['Изображения', []],
            'section_6': ['Эмпатия', []],
            'section_7': ['Смешивание', []],
            'section_8': ['Влияние на эмоции других', []],
            'section_9a': ['Ситуации', []],
            'section_9b': ['Динамика', []]
        }
        const rangeValues = {
            elementary: [],
            primary: [],
            base: [],
            average: [],
            advanced: [],
            high: [],
            professional: []
        }
        const maximumValue = {
            elementary: [],
            primary: [],
            base: [],
            average: [],
            advanced: [],
            high: [],
            professional: []
        }
        const averageValue = (sectionName) => {
            const sumWithInitial = (users.value.map((user) => user[sectionName])).reduce((a, b) => a + b);
            return ((sumWithInitial / (users.value.map((user) => user[sectionName])).length).toFixed(2));
        }
        const sorting = (user, sectionName, minVal, maxVal) => {
            return user[sectionName] >= minVal && user[sectionName] <= maxVal
        }
        const chartData = (sectionName) => {
            return [
                rangeValues.elementary[sectionName] = users.value.filter((user) => sorting(user, sectionName, 0, 69)),
                rangeValues.primary[sectionName] = users.value.filter((user) => sorting(user, sectionName, 70, 89)),
                rangeValues.base[sectionName] = users.value.filter((user) => sorting(user, sectionName, 90, 95)),
                rangeValues.average[sectionName] = users.value.filter((user) => sorting(user, sectionName, 96, 99)),
                rangeValues.advanced[sectionName] = users.value.filter((user) => sorting(user, sectionName, 100, 109)),
                rangeValues.high[sectionName] = users.value.filter((user) => sorting(user, sectionName, 110, 129)),
                rangeValues.professional[sectionName] = users.value.filter((user) => sorting(user, sectionName, 130, 150)),
            ]
        }
        const setColor = (sectionName) => {
            let scoreFrom = referenceReport.value.score[sectionName].score_from
            for (let item in rangeValues) {
                if (rangeValues[item][sectionName].length > 0) {
                    maximumValue[item][sectionName] = rangeValues[item][sectionName].map((item) => {
                        return item[sectionName]
                    });
                    if (scoreFrom >= Math.max.apply(null, maximumValue[item][sectionName])) {
                        sections[sectionName][1].push('#B9B9B9')
                    } else {
                        sections[sectionName][1].push('#2cc185')
                    }
                }
            }
        }
        for (let sectionName in sections) {
            let scoreFrom = referenceReport.value.score[sectionName].score_from
            chartData(sectionName)
            sections[sectionName].push(averageValue(sectionName))
            sections[sectionName].push(scoreFrom)
            setColor(sectionName)
        }
        const setLabel = (context) => {
            let persons = context.dataset.dataPersons[context.dataIndex]
            let women = persons.filter(person => person.sex == 'female')
            let men = persons.filter(person => person.sex == 'male')
            let youngMen = men.filter(man => man.age <= 25)
            let averageMen = men.filter(man => man.age >= 26 && man.age <= 35)
            let matureMen = men.filter(man => man.age >= 36 && man.age <= 55)
            let adultMen = men.filter(man => man.age >= 56)
            let youngWomen = women.filter(woman => woman.age <= 25)
            let averageWomen = women.filter(woman => woman.age >= 26 && woman.age <= 35)
            let matureWomen = women.filter(woman => woman.age >= 36 && woman.age <= 55)
            let adultWomen = women.filter(woman => woman.age >= 56)
            return [
                persons.length + ' человек с уровнем ',
                intervals[context.dataIndex] + ' баллов. ',
                'Подходит условиям KPI. ',
                men.length + ' мужчины ',
                'От 18 до 25: ' + youngMen.length,
                'От 26 до 35: ' + averageMen.length,
                'От 36 до 55: ' + matureMen.length,
                'От 56  и старше: ' + adultMen.length,
                women.length + ' женщин',
                'От 18 до 25: ' + youngWomen.length,
                'От 26 до 35: ' + averageWomen.length,
                'От 36 до 55: ' + matureWomen.length,
                'От 56  и старше: ' + adultWomen.length,
            ];
        }
        const sectionValue = (sectionName) => {
            return [
                rangeValues.elementary[sectionName].length,
                rangeValues.primary[sectionName].length,
                rangeValues.base[sectionName].length,
                rangeValues.average[sectionName].length,
                rangeValues.advanced[sectionName].length,
                rangeValues.high[sectionName].length,
                rangeValues.professional[sectionName].length
            ]
        }
        const intervals = ['0-69', '70-89', '90-95', '96-99', '100-109', '110-129', '130-150']
        const levelDictionary = ['Элементарный', 'Начальный', 'Базовый', 'Средний', 'Продвинутый', 'Высокий', 'Профессиональный']
        const charOptions = ref({
                plugins: {
                    legend: {
                        display: false
                    },
                    subtitle: {
                        display: true,
                        text: 'Низкий уровень EI           Средний уровень EI           Высокий уровень EI',
                        position: 'bottom'
                    },
                    tooltip: {
                        usePointStyle: true,
                        callbacks: {
                            label: function (context) {
                                return setLabel(context)
                            },
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            display: true,
                            padding: 10,
                        },
                        grid: {
                            display: false
                        },
                    },
                    y: {
                        ticks: {
                            display: true,
                            stepSize: 1
                        },
                        grid: {
                            display: false
                        },
                    },
                },
            }),
            chartWidth = ref(700),
            chartHeight = ref(400)

        const metadata = (sectionName) => {
            return {
                labels: levelDictionary,
                datasets: [
                    {
                        data: sectionValue(sectionName),
                        backgroundColor: sections[sectionName][1],
                        dataPersons: chartData(sectionName),
                        borderRadius: 10,
                        borderSkipped: false,
                    }
                ]
            }
        }
        return {metadata, charOptions, sections, chartWidth, chartHeight, referenceReport};
    }
}
</script>
<style lang="scss" scoped>
.analysis {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;

    @media(max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }

  &__content {
    border-bottom: solid 1px #c9c9c9;
    padding-bottom: 10px;
  }

  &__description {
    padding-bottom: 20px;
  }
}

</style>
