<template>
    <h3 class="table-title">Лучшие и худшие сотрудники:</h3>
    <ag-grid-vue :columnDefs="columnDefs.value" :rowData="rowData" :rowHeight="70" :headerHeight="150"
                 :getRowStyle="getRowStyle"
                 :enableCellTextSelection="true" :ensureDomOrder="true" :pagination="true"
                 :paginationPageSize="countOnPage"
                 :defaultColDef="defaultColDef" :suppressRowHoverHighlight="true" :suppressPaginationPanel="true"
                 :suppressRowClickSelection="true" :suppressRowTransform="true" style="width: 100%"
                 domLayout="autoHeight"
                 @grid-ready="onGridReady"
    >
    </ag-grid-vue>
    <table-pagination :gridApi="gridApi" :totalPages="gridApi ? gridApi.paginationGetTotalPages() : 0"
                      :countOnPage="countOnPage"/>
    <div class="table-footer">
        <div>
            <button class="btn btn-accent table-footer-btn" @click="sortByIdentifyCandidates">
                <span v-if="!isActive">Выявить кандидатов в
                соответствии с
                эталоном</span>
                <span v-if="isActive">Показать всех</span>
            </button>
        </div>
        <!--        <div>-->
        <!--            <i class="fa fa-user-circle" aria-hidden="true"></i>-->
        <!--            Кстати, есть подходящие кандидаты в других-->
        <!--            отделах-->
        <!--            <i class="fa fa-angle-down" aria-hidden="true"></i>-->
        <!--        </div>-->
    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue3";
import {reactive, ref} from 'vue'
import {useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import tableFieldsList from "./tableFieldsList"

export default {
    components: {
        TablePagination,
        AgGridVue
    },
    name: "users-table",
    setup() {
        const store = useStore(),
            gridApi = ref(null),
            columnApi = ref(null);
        const onGridReady = (params) => {
            gridApi.value = params.api;
            columnApi.value = params.columnApi;

        };
        const countOnPage = ref(10),
            page = ref(1);
        const isActive = ref(false)
        const referenceReport = computed(() => store.state.analytics.referenceReport)

        const buildMapFromObject = (obj, props) => {
            let resultObj = new Map();
            for (let i = 0; i < props.length; i++) {
                const cur = props[i];
                if (cur in obj) {
                    resultObj.set(cur, obj[cur])
                }
            }
            return resultObj;
        };

        let measuringParameters = buildMapFromObject(referenceReport.value.score, Object.keys(referenceReport.value.score).filter(key => referenceReport.value.score[key].key));

        function setGroup(data) {
            const total = 2 * measuringParameters.size
            let result = 0
            for (const [key, value] of Object.entries(data)) {
                if (!measuringParameters.has(key)) {
                    continue
                }
                const section = measuringParameters.get(key)
                const low = section.score_from
                const upper = section.score_to

                switch (true) {
                    case value >= upper:
                        result += 2
                        break
                    case value > low:
                        result += 1
                        break
                }
            }

            if (result === 0) {
                return 3
            } else if (result === total) {
                return 1
            } else {
                return 2
            }
        }

        const users = computed(() => store.state.analytics.referenceReport.users),
            rowData = computed(() => {
                return users.value.map((user, index) => {
                    return {
                        id: index,
                        name: user.name,
                        domain_1: user.domain_1,
                        total: user.total,
                        domain_2: user.domain_2,
                        branch_2: user.branch_2,
                        branch_3: user.branch_3,
                        section_2: user.section_2,
                        section_6: user.section_6,
                        kpi: user.kpi,
                        section_7: user.section_7,
                        group: setGroup(user) || 3
                    }
                })
            })
        const columnDefs = reactive(tableFieldsList);
        const defaultColDef = {
            suppressMovable: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            floatingFilter: true,
            resizable: true,
            sortable: true,
        };

        const getRowStyle = params => {
            if (params.data.group === 1) {
                return {color: '#00e287'};
            } else if (params.data.group === 2) {
                return {color: '#e2b500'};
            } else if (params.data.group === 3) {
                return {color: 'red'};
            }
        };

        function sortByIdentifyCandidates() {
            isActive.value = !isActive.value;
            if (isActive.value) {
                this.columnApi.applyColumnState({
                    state: [{colId: 'group', sort: 'asc'}],
                    defaultState: {sort: null},
                });
                this.gridApi.setRowData(this.rowData.filter(el => el.group !== 3))
            } else {
                this.columnApi.resetColumnState();
                this.gridApi.setRowData(this.rowData)
            }
        }

        return {
            countOnPage,
            columnDefs,
            rowData,
            defaultColDef,
            gridApi,
            onGridReady,
            columnApi,
            page,
            getRowStyle,
            sortByIdentifyCandidates,
            isActive,
        }
    },


}
</script>

<style lang="scss" scoped>

.table-title {
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  text-align: start;
  word-wrap: break-word;

  @media (max-width: 900px) {
    font-size: 20px;
    line-height: 24px;
    width: 225px;
  }
}

.table-footer {
  padding-top: 30px;
  padding-bottom: 43px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.table-footer-btn {
  margin-left: 0;
  margin-bottom: 10px
}
</style>