<template>
    <p>Тестирование прошли {{ sampleData.total }} человек.</p>
    <div class="samples-box">
        <ul><b>Мужчины:</b><br>{{ sampleData.malePercent }}% ({{ sampleData.maleCount }} человек)
            <li class="box-item">От 18 до 25<span>{{ sampleData.maleYoung }} человек</span></li>
            <li class="box-item">От 26 до 35<span>{{ sampleData.maleMiddle }} человек</span></li>
            <li class="box-item">От 35 до 55<span>{{ sampleData.maleAdult }} человек</span></li>
            <li class="box-item">От 56 и старше<span>{{ sampleData.maleAged }} человек</span></li>
        </ul>
        <ul><b>Женщины:</b><br>{{ sampleData.femalePercent }}% ({{ sampleData.femaleCount }} человек)
            <li class="box-item">От 18 до 25<span>{{ sampleData.femaleYoung }} человек</span></li>
            <li class="box-item">От 26 до 35<span>{{ sampleData.femaleMiddle }} человек</span></li>
            <li class="box-item">От 35 до 55<span>{{ sampleData.femaleAdult }} человек</span></li>
            <li class="box-item">От 56 и старше<span>{{ sampleData.femaleAged }} человек</span></li>
        </ul>
    </div>
    <span class="sample-description-title" style="display: none">Описание выборки:</span>
    <div class="sample-description" style="display: none">
        <p>это статистический анализ эмоционально интеллектуальных профилей сотрудников определенной
            должности в тандеме с их уровнями достижения KPI. Такой анализ использует объективные данные о
            способностях идентификации, использования, понимания и управления эмоциями для выявления
            наиболее важных параметров эмоционального интеллекта, влияющих на эффективность сотрудника на
            рабочем месте.
        </p>
        <p>В этом отчете Вы найдете эталонный ЭИ-профиль сотрудника с выделением ключевых параметров
            эмоционального интеллекта, обеспечивающих эффективность и достижение KPI в рамках
            профессиональной деятельности. ЭИ-профиль используется для найма новых сотрудников, обладающих
            необходимыми компетенциями ЭИ, ротации имеющихся, создания кадрового резерва.
        </p>
    </div>
</template>

<script>
import {ref} from 'vue'
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {computed} from "@vue/runtime-core";

export default {
    name: "sample-table",
    setup() {
        const store = useStore();
        let sampleData = ref({
            total: null,
            malePercent: null,
            maleCount: null,
            maleYoung: null,
            maleMiddle: null,
            maleAdult: null,
            maleAged: null,
            femalePercent: null,
            femaleCount: null,
            femaleYoung: null,
            femaleMiddle: null,
            femaleAdult: null,
            femaleAged: null
        })

        const sexAgeFemale = computed(() => store.state.analytics.referenceReport.sex_and_age_segregation.female),
            sexAgeMale = computed(() => store.state.analytics.referenceReport.sex_and_age_segregation.male)
        if (sexAgeMale.value) {
            sampleData = {
                total: sexAgeFemale.value?.count + sexAgeMale.value?.count,
                malePercent: sexAgeMale.value?.percent.toFixed(1),
                maleCount: sexAgeMale.value?.count,
                maleYoung: sexAgeMale.value?.maleYoung,
                maleMiddle: sexAgeMale.value?.maleMiddle,
                maleAdult: sexAgeMale.value?.maleAdult,
                maleAged: sexAgeMale.value?.maleAged,
                femalePercent: sexAgeFemale.value?.percent.toFixed(1),
                femaleCount: sexAgeFemale.value?.count,
                femaleYoung: sexAgeFemale.value?.femaleYoung,
                femaleMiddle: sexAgeFemale.value?.femaleMiddle,
                femaleAdult: sexAgeFemale.value?.femaleAdult,
                femaleAged: sexAgeFemale.value?.femaleAged,
            }
        }
        return {
            sampleData
        }
    },

    mounted() {
        const store = useStore(),
            route = useRoute();
        store.dispatch("analytics/getReferenceReport", route.params.id)
    }
}
</script>

<style lang="scss" scoped>
.sample-description-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
}

.samples-box {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 30px;
  padding-bottom: 40px;

  @media(max-width: 900px) {
    grid-template-columns: 227px;
  }
}

.sample-description {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
  border-bottom: solid 1px #c9c9c9;

  @media(max-width: 900px) {
    grid-template-columns: 227px;
    font-size: 12px;
    line-height: 17px;
  }
}

.box-item {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #c9c9c9;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>