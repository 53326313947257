<template>
    <div class="report-card-content">
        <h3 class="report-card-title">{{ title }}</h3>
        <p class="report-card-description">{{ range }} и более баллов</p>
        <p>{{ description }}</p>
    </div>
</template>

<script>
export default {
    name: "detailed-report-card-item",
    components: {},
    props: {
        title: {
            type: String,
        },
        range: {
            type: String,
        },
        description: {
            type: String,
        },
    }
}
</script>

<style lang="scss" scoped>
.report-card {
  &__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 42px;
  }

  &__description {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
}
</style>