<template>
    <li v-for="item in items" :key="item.index" :class="structure ? item.structureClass : null">
        <parameter-card-item :title="item.title" :structure="structure" :important="item.important" :min="item.min"
                             :max="item.max"
                             :descriptionContent="item.descriptionContent" :descriptionFooter="item.descriptionFooter"
                             :detailingClass="item.detailingClass"/>
    </li>
    <div v-show="structure" class="arrowMobile"></div>
    <div v-show="structure" class="arrow"></div>
    <div v-show="structure" class="arrow2"></div>
    <p v-show="structure" class="detailing-domain__experienced">Опытный ЭИ</p>
    <p v-show="structure" class="detailing-domain__strategic">Стратегический ЭИ</p>
    <div v-show="structure" class="arrow4"></div>
    <div v-show="structure" class="arrow5"></div>
    <div v-show="structure" class="arrow6"></div>
    <div v-show="structure" class="arrow7"></div>
</template>

<script>

import ParameterCardItem from "./ParameterCardItem/ParameterCardItem.vue"

export default {
    name: "parameters-cards",
    components: {ParameterCardItem},
    props: {
        items: {
            type: Object,
        },
        structure: {
            type: Boolean
        }
    }
}
</script>

<style lang="scss" scoped>
.general {
  grid-area: general;
}

.experienced {
  grid-area: experienced;
}

.strategic {
  grid-area: strategic;
}

.recognition {
  grid-area: recognition;
}

.using {
  grid-area: using;
}

.understanding {
  grid-area: understanding;
}

.management {
  grid-area: management;
}

.faces {
  grid-area: faces;
}

.facilitation {
  grid-area: facilitation;
}

.changes {
  grid-area: changes;
}

.managingYour {
  grid-area: managingYour;
}

.images {
  grid-area: images;
}

.empathy {
  grid-area: empathy;
}

.mixing {
  grid-area: mixing;
}

.influencing {
  grid-area: influencing;
}

.situations {
  grid-area: situations;
}

.dynamics {
  grid-area: dynamics;
}

.unimportant {
  background-color: transparent;
  border: dashed 2px #139460;
}

.arrow4,
.arrow5,
.arrow6,
.arrow7 {
  background-image: url(../../../assets/images/structure/arrow-line.svg);
  height: 25px;
  width: 110px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.arrow4 {
  grid-area: arrow4;
}

.arrow5 {
  grid-area: arrow5;
}

.arrow6 {
  grid-area: arrow6
}

.arrow7 {
  grid-area: arrow7;
}

.arrowMobile {
  grid-area: arrowMobile;
  background-image: url("../../../assets/images/structure/arrow-small.svg");
  height: 70px;
  width: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 850px) {
    height: 40px;
    width: 200px;
  }
}

.arrow,
.arrow2 {
  background-image: url("../../../assets/images/structure/arrow.svg");
  height: 50px;
  width: 264px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 1400px) {
    display: none;
  }
}

.arrow {
  grid-area: arrow;
}

.arrow2 {
  grid-area: arrow2;
}

.detailing-domain {

  &__experienced,
  &__strategic {
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    padding-top: 20px;
    display: none;

    @media (max-width: 1400px) {
      display: block;
    }

    @media (max-width: 850px) {
      font-size: 14px;
      line-height: 19px;
      padding-top: 0;
    }
  }

  &__experienced {
    grid-area: title-experienced;
  }

  &__strategic {
    grid-area: title-strategic;
  }
}
</style>